import React from 'react';
import { Result } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';

function SuccessDisplay(props) {
  return (
    <Result
      status="success"
      icon={ <CheckCircleTwoTone /> }
      title={ <div style={{ fontSize: '32px', fontWeight: 700 }}> 電表設定成功 </div> }
    />
  );
}

export default SuccessDisplay;
