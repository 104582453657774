import React, { useState, useRef } from 'react';
import { Col, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { apiIpcRegister, apiGetIpcByUuid } from '../../apis/iot-hub.js';

function SyncDevices(props) {
  const firstSync = useRef(false);
  const [ syncLoading, setSyncLoading ] = useState(true);
  const querySyncStatusCount = useRef(0);

  const getDeviceSyncStatus = () => {
    // do this at most 30 minutes (3 seconds * 600)
    if( querySyncStatusCount.current < 600 ) {
      querySyncStatusCount.current += 1;

      apiGetIpcByUuid(props.currentIpc.uuid).then((res) => {
        if( res.status === 200 ) {
          if( res.data.synced === true ) {
            props.goNextStep();
          } else {
            setTimeout(() => getDeviceSyncStatus(), 3000);
          }
        } else {
          setTimeout(() => getDeviceSyncStatus(), 3000);
        }
      }).catch((err) => {
        setTimeout(() => getDeviceSyncStatus(), 3000);
      });
    } else {
      showErrorModal();
      setSyncLoading(false);
    }
  };

  const syncDevices = () => {
    setSyncLoading(true);

    apiIpcRegister(props.serialNumber, props.tenantId).then((res) => {
      if( res.status === 200 ) {
        getDeviceSyncStatus();
      } else {
        showErrorModal();
        setSyncLoading(false);
      }
    }).catch((err) => {
      showErrorModal();
      setSyncLoading(false);
    });
  };

  const showErrorModal = () => {
    Modal.error({
      title: "同步設備至此 IPC 失敗。",
      content: "請稍後重試，或聯絡管理員排除錯誤。",
      centered: true,
      maskClosable: false,
      okText: "確定",
      width: '70vh'
    });
  };

  if( firstSync.current === false ) {
    firstSync.current = true;
    syncDevices();
  }

  return (
    syncLoading
    ?
      <>
        <Col span={ 24 } className="center flex-column">
          <div className="start-process-text"> 正在同步設備 </div>
          <div className="start-process-text"> 請耐心等候 </div>
          <LoadingOutlined className="start-process-icon" />
        </Col>
      </>
    :
      <>
        <Col span={ 24 } className="center flex-column">
          <div className="start-process-text"> 同步設備尚未成功 </div>
          <div className="start-process-text"> 請重新整理頁面查看最新狀態 </div>
        </Col>
      </>
  );
}

export default SyncDevices;
