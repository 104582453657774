import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import * as queryString from 'query-string';
import { checkCookieForLoginStatus } from './utils.js';

import QrcodeInstallation from './components/qrcode-installation/qrcode-installation.js';
import ElectricMeterConfigSetting from './components/electric-meter-config-setting/electric-meter-config-setting.js';

// css
import './App.css';

// App
function App() {
  const [ initOK, setInitOK ] = useState(false);
  const [ isLogin, setIsLogin ] = useState(false);

  useEffect(() => {
    let tmpIsLogin = checkCookieForLoginStatus();
    if( !tmpIsLogin ) {
      let loginType = '&loginType=others';
      const params = queryString.parse(window.location.search);
      if( params !== null ) {
        if( params["ipcType"] === 'meter' ) {
          loginType = '';
        }
      }

      window.location.replace(`${process.env.REACT_APP_SSO_URL}login?redirectUri=${window.location.href}${loginType}`);
    } else {
      setIsLogin(tmpIsLogin);
      setInitOK(true);
    }
  }, []);

  return (
    <BrowserRouter>
      { initOK && isLogin
        ?
          <Routes>
            <Route path="/" element={ <Navigate replace to="/register" /> } />
            <Route path='/register' element={ <QrcodeInstallation /> } />
            <Route path='/electricMeterConfigSetting' element={ <ElectricMeterConfigSetting /> } />
          </Routes>
        :
          null
      }
    </BrowserRouter>
  );
}

export default App;
