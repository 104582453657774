import React from 'react';
import { Card } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import { isDesktop } from 'react-device-detect';

function SelectTopology(props) {
  const widthRatio = isDesktop ? '40%' : '80%';
  return (
    <div className="full-width-height center flex-column" style={{ width: '100%', height: '100%' }}>
      <div className="full-width center" style={{ fontSize: '32px', fontWeight: 700 }}>
        請選擇電表監控群類型
      </div>
      <div className="full-width center" style={{ marginTop: '48px' }}>
        <Card
          className="center"
          hoverable
          style={{ width: widthRatio, borderRadius: '15px', fontSize: '26px', borderWidth: '3px' }}
          onClick={ () => {
            props.setElectricMeterTopology(1);
            props.goNextStep();
          }}
        >
          <RightCircleOutlined style={{ marginRight: '18px', color: '#1890FF' }} />
          單產線兩設備
        </Card>
      </div>
      <div className="full-width center" style={{ marginTop: '32px' }}>
        <Card
          className="center"
          hoverable
          style={{ width: widthRatio, borderRadius: '15px', fontSize: '26px', borderWidth: '3px' }}
          onClick={ () => {
            props.setElectricMeterTopology(2);
            props.goNextStep();
          }}
        >
          <RightCircleOutlined style={{ marginRight: '18px', color: '#1890FF' }} />
          雙產線
        </Card>
      </div>
    </div>
  );
}

export default SelectTopology;
