import React, { useEffect, useState } from 'react';
import { Result, Button } from 'antd';
import { AlertFilled, MehOutlined } from '@ant-design/icons';
import * as queryString from 'query-string';
import { apiGetIpcBySerialNumber } from '../../apis/iot-hub.js';
import { apiGetElectricMeterModbusConfigIsCreated } from '../../apis/device-topology.js';
import SelectTopology from './select-topology.js';
import SetMachineIp from './set-machine-ip.js';
import SubmitConfig from './submit-config.js';
import SuccessDisplay from './success-display.js';
import './electric-meter-config-setting.css';

function ElectricMeterConfigSetting() {
  const [ initOK, setInitOK ] = useState(false);
  const [ currentIpc, setCurrentIpc ] = useState(null);
  const [ getIpcError, setGetIpcError ] = useState(false);
  const [ getConfigError, setGetConfigError ] = useState(false);
  const [ validParams, setValidParams ] = useState(false);
  const [ serialNumber, setSerialNumber ] = useState('');
  const [ ipcNotRegister, setIpcNotRegister ] = useState(false);
  const [ ipAddress, setIpAddress ] = useState('');
  const [ settingStep, setSettingStep ] = useState(0);
  const [ configIsCreated, setConfigIsCreated ] = useState(false);

  // for setting config
  const [ electricMeterTopology, setElectricMeterTopology ] = useState(0);

  useEffect(() => {
    let tmpSerialNumber = '';
    let tmpValidParams = false;

    // check parameters and login status
    const params = queryString.parse(window.location.search);
    if( params !== null && params.sn !== undefined && params.sn.trim() !== '' ) {
      tmpSerialNumber = params.sn;
      tmpValidParams = true;
    } else {
      tmpValidParams = false;
    }

    if( tmpValidParams ) {
      apiGetIpcBySerialNumber(tmpSerialNumber).then((res) => {
        switch(res.status) {
          case 200:
            if( res.data.length === 0 ) {
              setIpcNotRegister(true);
            } else {
              if( res.data[0].synced === true ) {
                // get modbus config
                apiGetElectricMeterModbusConfigIsCreated(res.data[0].uuid).then((resConfig) => {
                  switch(resConfig.status) {
                    case 200:
                      setSettingStep(resConfig.data ? 2 : 0);
                      setConfigIsCreated(resConfig.data);
                      break;
                    default:
                      setGetConfigError(true);
                  }
                }).catch((err) => {
                  setGetConfigError(true);
                }).then(() => {
                  setGetConfigError(false);
                  setValidParams(tmpValidParams);
                  setInitOK(true);
                });

                /*
                setSettingStep(2);
                setConfigIsCreated(true);
                setGetConfigError(false);
                setValidParams(tmpValidParams);
                setInitOK(true);
                */

                // ipc.synced is true, means ipc register ok
                setIpcNotRegister(false);
              } else {
                setIpcNotRegister(true);
              }
              setCurrentIpc(res.data[0]);
            }
            break;
          default:
            setGetIpcError(true);
        }
      }).catch((err) => {
        setGetIpcError(true);
      }).then(() => {
        setSerialNumber(tmpSerialNumber);
        setGetIpcError(false);
      });
    } else {
      setValidParams(tmpValidParams);
      setInitOK(true);
    }
  }, []);

  const generateDisplayByCurrentStep = () => {
    switch(settingStep) {
      case 0:
        return (
          <SelectTopology
            setElectricMeterTopology={ (value) => setElectricMeterTopology(value) }
            goNextStep={ goNextStep }
          />
        );
      case 1:
        return (
          <SetMachineIp
            setIpAddress={ (value) => setIpAddress(value) }
            goLastStep={ goLastStep }
            goNextStep={ goNextStep }
          />
        );
      case 2:
        return (
          <SubmitConfig
            configIsCreated={ configIsCreated }
            setConfigIsCreated={ (value) => { setConfigIsCreated(value); }}
            currentIpc={ currentIpc }
            ipAddress={ ipAddress }
            electricMeterTopology={ electricMeterTopology }
            goNextStep={ goNextStep }
          />
        );
      case 3:
        return (
          <SuccessDisplay />
        );
      default:
        return null;
    }
  };

  const goNextStep = () => {
    setSettingStep((step) => step + 1);
  };

  const goLastStep = () => {
    setSettingStep((step) => step - 1);
  };

  return (
    initOK
    ?
      <div className="full-width-height center">
        { validParams
          ?
            !getIpcError && !getConfigError
            ?
              !ipcNotRegister
              ?
                generateDisplayByCurrentStep()
              :
                <Result
                  icon={ <AlertFilled className="alert-icon" style={{ color: 'rgb(230, 18, 32)' }} /> }
                  title={ <div> 此電腦尚未完成安裝流程，請先完成安裝。 </div> }
                  extra={ <Button type="primary" href={ `/register?sn=${serialNumber}&ipcType=meter` }> 前往安裝流程 </Button> }
                />
            :
              <Result
                status="error"
                icon={ <MehOutlined /> }
                title={ <div> 目前無法獲取此電腦或電表設定的資訊，請稍後再試。 </div> }
              />
          :
            <Result
              status="warning"
              title={ <div> 網址參數有誤，請重新掃描 QR Code 。 </div> }
            />
        }
      </div>
    :
      null
  );
}

export default ElectricMeterConfigSetting;
