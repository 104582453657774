import axios from 'axios';
import Cookies from 'js-cookie';

export const apiPostIpc = (data) => {
  const token = Cookies.get("token");
  if( token === null || token === undefined || token === '' ) {
    return Promise.reject();
  }

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_IOTHUB_API}devices`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: data
  }).then((res) => {
    return res;
  });
};

export const apiGetIpcBySerialNumber = (sn) => {
  const token = Cookies.get("token");
  if( token === null || token === undefined || token === '' ) {
    return Promise.reject();
  }

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_IOTHUB_API}devices`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      sn: sn
    }
  }).then((res) => {
    return res;
  });
};

export const apiGetIpcByUuid = (uuid) => {
  const token = Cookies.get("token");
  if( token === null || token === undefined || token === '' ) {
    return Promise.reject();
  }

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_IOTHUB_API}devices/${uuid}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((res) => {
    return res;
  });
};

export const apiGetIpcListByTenantId = (tenantId) => {
  const token = Cookies.get("token");
  if( token === null || token === undefined || token === '' ) {
    return Promise.reject();
  }

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_IOTHUB_API}devices`,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-TENANT-ID': tenantId
    }
  }).then((res) => {
    return res;
  });
};

export const apiIpcRegister = (sn, tenantId) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_IOTHUB_API}registration`,
    data: { sn: sn, tenant_id: tenantId }
  }).then((res) => {
    return res;
  });
};

export const apiPostIpcSyncAction = (ipcUuid) => {
  const token = Cookies.get("token");
  if( token === null || token === undefined || token === '' ) {
    return Promise.reject();
  }

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_IOTHUB_API}devices/${ipcUuid}/actions`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      name: "sync_devices",
      args: "",
      activated: true
    }
  }).then((res) => {
    return res;
  });
};

export const apiPostDownloadModbusConfigAction = (ipcUuid) => {
  const token = Cookies.get("token");
  if( token === null || token === undefined || token === '' ) {
    return Promise.reject();
  }

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_IOTHUB_API}devices/${ipcUuid}/actions`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      name: "download_modbus_config",
      args: "",
      activated: true
    }
  }).then((res) => {
    return res;
  });
};

export const apiGetActionState = (ipcUuid, actionUuid) => {
  const token = Cookies.get("token");
  if( token === null || token === undefined || token === '' ) {
    return Promise.reject();
  }

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_IOTHUB_API}devices/${ipcUuid}/actions/${actionUuid}`,
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }).then((res) => {
    return res;
  });
};

