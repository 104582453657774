// handle cookie things
import Cookies from 'js-cookie';

// handle jwt ( get expire time )
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';

export function checkCookieForLoginStatus() {
  const user = Cookies.get("user");
  const token = Cookies.get("token");
  const refreshToken = Cookies.get("refreshToken");

  if( user === undefined || user === '' ) {
    return false;
  }

  if( token === undefined || token === '' ) {
    return false;
  }

  if( refreshToken === undefined || refreshToken === '' ) {
    return false;
  }

  // check token expired
  let tokenDecoded = jwtDecode(token);
  const exp = dayjs.unix(tokenDecoded.exp);

  // expire time is before current time, means user token is expired
  if( exp.isBefore(dayjs()) ) {
    return false;
  }

  return true;
};

export function getLoginInfo() {
  // get user, token and refreshToken from Cookies
  const user = Cookies.get("user");
  const token = Cookies.get("token");
  const refreshToken = Cookies.get("refreshToken");

  // check again
  if( user === undefined || token === undefined || refreshToken === undefined ) {
    return { user: null, token: '', refreshToken: '' };
  }

  // get tenantId from user
  const userObj = JSON.parse(user);
  if( userObj.hasOwnProperty("tenantId") === false ) {
    return { user: null, token: '', refreshToken: '' };
  }

  return { user: userObj, token: token, refreshToken: refreshToken };
};

