import React from 'react';
import { Col, Button } from 'antd';
import { CheckCircleTwoTone, SettingFilled } from '@ant-design/icons';
import { isDesktop } from 'react-device-detect';

function SuccessDisplay(props) {
  const widthRatio = isDesktop ? '40%' : '80%';
  const electricMeterRedirectLink = `/electricMeterConfigSetting?sn=${props.currentIpc.sn}`;
  return (
    props.ipcMachineType === 'meter'
    ?
      <>
        <Col span={ 24 } className="center" style={{ textAlign: 'center' }}>
          { isDesktop
            ?
              <div className="success-text"> 此電腦已新增至雲端，請繼續完成設定 </div>
            :
              <div className="success-text">
                此電腦已新增至雲端
                <br />
                請繼續完成設定
              </div>
          }
        </Col>
        <Col span={ 24 } className="center">
          <div style={{ width: widthRatio, height: '80px' }}>
            <Button
              type="primary"
              icon={ <SettingFilled style={{ marginRight: '8px' }} /> }
              className="full-width-height"
              style={{ borderRadius: '15px', fontSize: '28px', fontWeight: 700 }}
              onClick={() => { window.location.href = `${window.location.origin}${electricMeterRedirectLink}`; }}
            >
              前往設定電表
            </Button>
          </div>
         </Col>
      </>
    :
      <>
        <Col span={ 24 } className="center">
          <div className="success-text"> 此 IPC 裝機流程已完成 </div>
        </Col>
        <Col span={ 24 } className="center">
          <CheckCircleTwoTone className="success-icon" twoToneColor="#52C41A" />
        </Col>
      </>
  );
}

export default SuccessDisplay;
