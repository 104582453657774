import axios from 'axios';
import Cookies from 'js-cookie';

export const apiGetElectricMeterModbusConfigIsCreated = (ipcUuid) => {
  const token = Cookies.get("token");
  if( token === null || token === undefined || token === '' ) {
    return Promise.reject();
  }

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_DEVICE_TOPOLOGY_API}modbusConfig/${ipcUuid}/electricMeter/isCreated`,
    headers: {
      Authorization: `Bearer ${token}`
    },
  }).then( (res) => {
    return res;
  });
};

export const apiPostElectricMeterModbusConfig = (ipcUuid, modbusConfig) => {
  const token = Cookies.get("token");
  if( token === null || token === undefined || token === '' ) {
    return Promise.reject();
  }

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_DEVICE_TOPOLOGY_API}modbusConfig/${ipcUuid}/electricMeter`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: modbusConfig
  }).then( (res) => {
    return res;
  });
};

