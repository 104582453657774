import React, { useRef, useState } from 'react';
import { Result, Button } from 'antd';
import { LoadingOutlined, MehOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { apiPostElectricMeterModbusConfig } from '../../apis/device-topology.js';
import { apiPostIpcSyncAction, apiPostDownloadModbusConfigAction, apiGetActionState } from '../../apis/iot-hub.js';

function SubmitConfig(props) {
  const firstSubmit = useRef(false);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ actionDone, setActionDone ] = useState(false);
  const [ executeState, setExecuteState ] = useState('');
  const [ hasError, setHasError ] = useState(false);
  const actionUuid = useRef(null);
  const pollingStatusCount = useRef(0);

  const submitConfig = () => {
    if( props.configIsCreated === false ) {
      setExecuteState("創建設定檔");
      setIsLoading(true);
      
      let config = {
        topologyType: props.electricMeterTopology,
        machineIp: props.ipAddress,
      };

      apiPostElectricMeterModbusConfig(props.currentIpc.uuid, config).then((res) => {
        if( res.status === 200 ) {
          props.setConfigIsCreated(true);
          createSyncIpcAction();
        } else {
          setHasError(true);
          setIsLoading(false);
        }
      }).catch((err) => {
        setHasError(true);
        setIsLoading(false);
      });

      /*
      setTimeout( () => {
        props.setConfigIsCreated(true);
        createSyncIpcAction();
      }, 3000);
      */
    } else {
      createSyncIpcAction();
    }
  };

  const createSyncIpcAction = () => {
    pollingStatusCount.current = 0;
    apiPostIpcSyncAction(props.currentIpc.uuid).then((res) => {
      if( res.status === 200 ) {
        actionUuid.current = res.data.uuid;
        pollingIpcSyncResult();
        setExecuteState("等待同步電表");
      } else {
        setHasError(true);
        setIsLoading(false);
      }
    }).catch((err) => {
      setHasError(true);
      setIsLoading(false);
    });

    /*
    setTimeout(() => {
      actionUuid.current = "a9a4b73c-1afb-46bb-b7c8-e35ce70f44a1";
      pollingIpcSyncResult();
      setExecuteState("等待同步電表");
    }, props.configIsCreated ? 0 : 3000);
    */
  };

  const pollingIpcSyncResult = () => {
    // polling at most 30 minutes (5 seconds * 360)
    if( pollingStatusCount.current < 360 ) {
      pollingStatusCount.current += 1;

      apiGetActionState(props.currentIpc.uuid, actionUuid.current).then((res) => {
        if( res.status === 200 ) {
          if( res.data.done === true ) {
            if( res.data.code === 200 ) {
              setHasError(false);
              setIsLoading(false);
            } else {
              pollingStatusCount.current = 0;
              setActionDone(true);
              setHasError(true);
              setIsLoading(false);
            }
          } else {
            if( res.data.fetched === true ) {
              createDownloadModbusConfigAction();
            } else {
              setTimeout(() => pollingIpcSyncResult(), 3000);
            }
          }
        } else {
          setTimeout(() => pollingIpcSyncResult(), 3000);
        }
      }).catch((err) => {
        setTimeout(() => pollingIpcSyncResult(), 3000);
      });
    } else {
      pollingStatusCount.current = 0;
      setActionDone(true);
      setHasError(true);
      setIsLoading(false);
    }

    /*
    setTimeout(() => {
      setTimeout(() => {
        createDownloadModbusConfigAction();
      }, 2000);
    }, 5000);
    */
  };

  const createDownloadModbusConfigAction = () => {
    pollingStatusCount.current = 0;
    apiPostDownloadModbusConfigAction(props.currentIpc.uuid).then((res) => {
      if( res.status === 200 ) {
        actionUuid.current = res.data.uuid;
        pollingDownloadResult();
        setExecuteState("等待下載設定檔");
      } else {
        setHasError(true);
        setIsLoading(false);
      }
    }).catch((err) => {
      setHasError(true);
      setIsLoading(false);
    });

    /*
    setTimeout(() => {
      actionUuid.current = "a9a4b73c-1afb-46bb-b7c8-e35ce70f44a1";
      pollingDownloadResult();
      setExecuteState("等待下載設定檔");
    }, props.configIsCreated ? 0 : 3000);
    */
  };

  const pollingDownloadResult = () => {
    // polling at most 30 minutes (5 seconds * 360)
    if( pollingStatusCount.current < 360 ) {
      pollingStatusCount.current += 1;

      apiGetActionState(props.currentIpc.uuid, actionUuid.current).then((res) => {
        if( res.status === 200 ) {
          if( res.data.done === true ) {
            if( res.data.code === 200 ) {
              setHasError(false);
              setIsLoading(false);
            } else {
              pollingStatusCount.current = 0;
              setActionDone(true);
              setHasError(true);
              setIsLoading(false);
            }
          } else {
            if( res.data.fetched === true ) {
              setExecuteState("開始下載設定檔");
            } else {
              setTimeout(() => pollingDownloadResult(), 3000);
            }
          }
        } else {
          setTimeout(() => pollingDownloadResult(), 3000);
        }
      }).catch((err) => {
        setTimeout(() => pollingDownloadResult(), 3000);
      });
    } else {
      pollingStatusCount.current = 0;
      setActionDone(true);
      setHasError(true);
      setIsLoading(false);
    }

    /*
    setTimeout(() => {
      setExecuteState("開始下載設定檔");

      setTimeout(() => {
        setActionDone(true);
        setHasError(true);
        setIsLoading(false);

        setHasError(false);
        setIsLoading(false);
      }, 2000);
    }, 5000);
    */
  };

  if( firstSubmit.current === false ) {
    firstSubmit.current = true;
    submitConfig();
  }

  return (
    <div className="full-width-height center">
      { isLoading
        ?
          <div className="full-width center flex-column">
            <LoadingOutlined style={{ fontSize: '84px', color: '#1890FF' }} />
            <div className="center" style={{ marginTop: '48px', fontSize: '48px', fontWeight: 700 }}>
              { executeState }
            </div>
          </div>
        :
          hasError
          ?
            <Result
              status="error"
              icon={ <MehOutlined style={{ fontSize: '96px' }} /> }
              title={
                <div style={{ fontSize: '48px', fontWeight: 700, margin: '48px 0px' }}>
                  { actionDone ? "下載設定檔失敗" : "出現錯誤" }
                </div>
              }
              subTitle={
                actionDone
                ?
                  <div className="flex-column center" style={{ fontSize: '32px',  margin: '0px 0px 48px 0px' }}>
                    <div>
                      { `請聯絡管理員` }
                    </div>
                    <div style={{ marginTop: '12px', fontSize: '16px' }}>
                      { `錯誤代碼：${actionUuid.current}` }
                    </div>
                  </div>
                :
                  null
              }
              extra={
                <Button type="primary" onClick={ submitConfig } style={{ width: '100%', height: '80px', fontSize: '28px' }}> 再試一次 </Button>
              }
            />
          :
            <Result
              icon={ <CheckCircleTwoTone twoToneColor="#52C41A" style={{ fontSize: '96px' }} /> }
              title={ <div style={{ fontSize: '48px', fontWeight: 700, margin: '48px 0px' }}> 電表設定完成 </div> }
            />
      }
    </div>
  );
}

export default SubmitConfig;
