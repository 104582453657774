import React from 'react';
import { Col } from 'antd';

function SelectProcess(props) {
  return (
    props.ipcMachineType === 'nc'
    ?
      <>
        <Col span={ 24 } className="center">
          <div
            className="center choose-process"
            onClick={() => {
              props.setInstallationType("production");
              props.goNextStep();
            }}
          >
            正式機安裝
          </div>
        </Col>
        <Col span={ 24 } className="center">
          <div
            className="center choose-process"
            onClick={ () => {
              props.setInstallationType("backup");
              props.goNextStep();
            }}
          >
            備用機安裝
          </div>
        </Col>
      </>
    :
      <>
        <Col span={ 24 } className="center">
          <div
            className="center choose-process"
            onClick={() => {
              props.setInstallationType("production");
              props.goNextStep();
            }}
          >
            下一步
          </div>
        </Col>
        <Col span={ 24 } className="center">
          <div
            className="center"
            style={{ color: 'indianRed', fontSize: '18px' }}
            onClick={() => {
              props.setInstallationType("production");
              props.goNextStep();
            }}
          >
            * 請確認當前組織是否正確
          </div>
        </Col>
      </>
  );
}

export default SelectProcess;
