import React, { useState } from 'react';
import { Modal, List, Row, Col, Input, Button } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import * as queryString from 'query-string';

function SwitchTenantModal(props) {
  const [ switchToByManual, setSwitchToByManual ] = useState('');
  const [ switchToByManualStatus, setSwitchToByManualStatus ] = useState(null);

  const onSwitchTenant = (switchTo) => {
    const params = queryString.parse(window.location.search);

    let loginType = '&loginType=others';
    if( params !== null ) {
      if( params["ipcType"] === 'meter' ) {
        loginType = '';
      }
    }

    window.location.replace(`${process.env.REACT_APP_SSO_URL}logout?redirectUri=${window.location.href}&tenantId=${switchTo}${loginType}`);
  };

  return (
    <Modal
      visible={ props.visible }
      title={
        <div>
          <SwapOutlined style={{ marginRight: '8px' }} />
          切換組織
        </div>
      }
      footer={ null }
      onCancel={ () => props.setVisible(false) }
      bodyStyle={{ height: '70vh', overflowY: 'auto', overflowX: 'hidden' }}
      centered={ true }
    >
      { props.customerSet !== null
        ?
          <List
            header={ null }
            footer={ null }
            dataSource={ props.customerSet }
            renderItem={ (item) => (
              <List.Item
                actions={[
                  <div key={ item.ID }>
                    { item.ID === props.currentTenantId
                      ?
                        <div className="current-tenant"> 當前 </div>
                      :
                        <div
                          className="other-tenant"
                          onClick={ () => onSwitchTenant(item.ID) }
                        >
                          切換
                        </div>
                    }
                  </div>,
                ]}
              >
                { item.title }
              </List.Item>
            )}
          />
        :
          <Row gutter={[ 0, 24 ]} justify="center" align="middle">
            <Col span={ 24 }>
              <div> 獲取組織列表失敗，請手動輸入要切換的組織 ID 。 </div>
            </Col>
            <Col span={ 24 }>
              <Input
                status={ switchToByManualStatus }
                placeholder="請輸入組織 ID"
                onChange={(e) => {
                  if( e.target.value.trim() !== '' ) {
                    setSwitchToByManualStatus(null);
                  }
                  setSwitchToByManual(e.target.value.trim());
                }}
              />
            </Col>
            <Col span={ 24 }>
              <Button
                type="primary"
                onClick={() => {
                  if( switchToByManual !== '' ) {
                    onSwitchTenant(switchToByManual);
                  } else {
                    setSwitchToByManualStatus('error');
                  }
                }}
              >
                切換組織
              </Button>
            </Col>
          </Row>
      }
    </Modal>
  );
}

export default SwitchTenantModal;
