import React, { useEffect, useState, useRef } from 'react';
import { Button, Input } from 'antd';
import { WarningOutlined, CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import { isDesktop } from 'react-device-detect';

function SetMachineIp(props) {
  const [ ipAddress, setIpAddress ] = useState('192.168.210.150');
  const [ ipError, setIpError ] = useState(false);
  const inputRef = useRef(null);

  useEffect( () => {
    if( inputRef !== null ) {
      inputRef.current.focus({ cursor: 'end' });
    }
  }, []);

  const ipOnChange = (e) => {
    let value = e.target.value.trim();

    setIpError(!validateIp(value));
    setIpAddress(value);
  };

  const validateIp = (ip) => {
    let ipv4 = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ip.match(ipv4);
  };

  const onEditOk = () => {
    if( validateIp(ipAddress) ) {
      props.setIpAddress(ipAddress);
      props.goNextStep();
    } else {
      if( inputRef !== null ) {
        inputRef.current.focus({ cursor: 'end' });
      }
      setIpError(true);
    }
  };

  const widthRatio = isDesktop ? '40%' : '80%';
  return (
    <div className="full-width-height center flex-column" style={{ width: '100%', height: '100%' }}>
      <div className="full-width center" style={{ fontSize: '28px', fontWeight: 700 }}>
        請設定 Modbus 連線 IP 位址
      </div>
      <div className="full-width center flex-column" style={{ marginTop: '100px' }}>
        <div
          className="center flex-row"
          style={{
            width: widthRatio,
            border: ipError ? '3px dashed #FF4D4F' : '3px dashed #1890FF',
            borderRadius: '15px',
            padding: '18px 22px',
          }}
        >
          <Input
            ref={ inputRef }
            value={ ipAddress }
            placeholder="請輸入 IP 位址"
            bordered={ false }
            style={{ padding: '0px', fontSize: '26px', textAlign: 'center' }}
            onChange={ ipOnChange }
            onPressEnter={ onEditOk }
          />
        </div>
        <div className="center" style={{ width: '80%', height: '78px', fontSize: '28px', color: '#FF4D4F', margin: '24px 0px', padding: '0px 22px' }}>
          { ipError
            ?
              <>
                <WarningOutlined  style={{ marginRight: '12px' }} />
                請輸入正確的 IP ！
              </>
            :
              null
          }
        </div>
        <div className="center" style={{ width: widthRatio }}>
          <div className="full-width flex-row" style={{ height: '80px', justifyContent: 'space-between' }}>
            <Button style={{ width: 'calc((100% - 48px) / 2)', height: '100%', fontSize: '28px' }} onClick={ props.goLastStep }>
              <CaretLeftFilled />
              <span style={{ marginRight: '8px' }}> 返回 </span>
            </Button>
            <Button
              type="primary"
              style={{ width: 'calc((100% - 48px) / 2)', height: '100%', fontSize: '28px' }}
              onClick={ () => {
                if( validateIp(ipAddress) ) {
                  props.setIpAddress(ipAddress);
                  props.goNextStep();
                } else {
                  if( inputRef !== null ) {
                    inputRef.current.focus({ cursor: 'end' });
                  }
                  setIpError(true);
                }
              }}
            >
              <span style={{ marginLeft: '8px' }}> 送出 </span>
              <CaretRightFilled />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetMachineIp;
