import axios from 'axios';
import Cookies from 'js-cookie';

export const apiGetCustomerSet = () => {
  const token = Cookies.get("token");
  if( token === null || token === undefined || token === '' ) {
    return Promise.reject();
  }

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_USER_API}permission/customerSet`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then( (res) => {
    return res.data;
  });
};

